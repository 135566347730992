<template>
  <div>
    <div class="content">
      <div class="title1 test1">
        <div class="title2">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          风险洞察对标
        </div>
        <div>
          <el-button @click="exportClick" type="primary" size="small"
            >导出分析结果</el-button
          >
        </div>
      </div>
      <div class="mt-3" id="tableBox">
        <table style="width: 100%" class="table table-bordered table-striped">
          <thead class="bgc-be color-0 text-center table-bordered">
            <tr>
              <th v-for="item in dataList" :key="item">
                <div>{{ item.company_name }}</div>
                <div>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ item.report_year }}
                  </el-tag>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ list[item.report_time] }}
                  </el-tag>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ item.report_type == "00" ? "合并报表" : "单体报表" }}
                  </el-tag>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="item in dataList" :key="item">
                <div class="row">
                  <span style="font-weight: 550"> 风险评估结论 </span>
                  <el-tag
                    size="small"
                    class="ml-2"
                    style="color: #fff; border-color: #3ebb9f"
                    color="#3EBB9F"
                    v-if="item.grade.risk == '风险低'"
                    >风险低</el-tag
                  >
                  <el-tag
                    size="small"
                    class="ml-2"
                    style="color: #fff; border-color: #f88e36"
                    color="#F88E36"
                    v-if="item.grade.risk == '风险中'"
                    >风险中</el-tag
                  >
                  <el-tag
                    size="small"
                    class="ml-2"
                    style="color: #fff; border-color: #ee5043"
                    color="#EE5043"
                    v-if="item.grade.risk == '风险高'"
                    >风险高</el-tag
                  >
                </div>
                <div class="text-center">
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'A'"
                    src="@/assets/img/detail/A.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'A-'"
                    src="@/assets/img/detail/A-.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'A+'"
                    src="@/assets/img/detail/A+.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'B'"
                    src="@/assets/img/detail/B.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'B-'"
                    src="@/assets/img/detail/B-.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'B+'"
                    src="@/assets/img/detail/B+.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'C'"
                    src="@/assets/img/detail/C.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'C-'"
                    src="@/assets/img/detail/C-.png"
                    alt=""
                  />
                  <img
                    style="width: 220px"
                    v-if="item.grade.grade == 'C+'"
                    src="@/assets/img/detail/C+.png"
                    alt=""
                  />
                </div>
                <div class="mt-2">
                  <div style="font-weight: 550">重点关注如下：</div>
                  <div v-if="item.focus.length == 0">
                    暂未发现需重点关注风险点
                  </div>
                  <div v-else style="max-width: 265px" class="mt-2">
                    <el-tag
                      v-for="tag in item.focus"
                      :key="tag"
                      size="small"
                      type="danger"
                      class="mr-2 mb-2"
                      >{{ tag[1] }}</el-tag
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas"; //文本转图片
export default {
  data() {
    return {
      list: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },
      dataList: [],
    };
  },
  methods: {
    exportClick() {
      const imgList = document.getElementById("tableBox");
      html2canvas(imgList).then((canvas) => {
        // 创建一个新的图像元素
        var img = new Image();
        // 设置图像的源为canvas生成的DataURL
        img.src = canvas.toDataURL("image/png");
        // 创建一个下载链接
        var link = document.createElement("a");
        link.href = img.src;
        link.download = "风险洞察对标.png"; // 名称可以修改
        link.click();
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 20px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  .title1 {
    font-size: 20px;
    color: #333;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 5px;
    }
  }
}
</style>