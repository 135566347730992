<template>
  <div>
    <div class="content">
      <div class="title1 test1">
        <div class="title2">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          结构组成对标
        </div>
        <div>
          <el-button type="primary" size="small" @click="exportClick"
            >导出分析结果</el-button
          >
        </div>
      </div>
      <div class="mt-3">
        <div>
          <el-button-group>
            <el-button
              :type="showTable == 0 ? 'primary' : ''"
              size="medium"
              @click="tableClick('0')"
              >资产负债表</el-button
            >
            <el-button
              :type="showTable == 1 ? 'primary' : ''"
              size="medium"
              @click="tableClick('1')"
              >利润表</el-button
            >
            <el-button
              :type="showTable == 2 ? 'primary' : ''"
              size="medium"
              @click="tableClick('2')"
              >现金流量表</el-button
            >
          </el-button-group>
        </div>
        <div class="mt-3">
          <!-- 资产 -->
          <FBtable v-show="showTable == 0" ref="fbtable"></FBtable>
          <!-- 利润 -->
          <F_table v-show="showTable == 1" ref="fptable"></F_table>
          <!-- 现金 -->
          <F_table v-show="showTable == 2" ref="fctable"></F_table>
        </div>
        <div
          class="mt-3"
          style="background: #e9f3ff; padding: 10px"
          v-show="showTable == 0"
        >
          <div class="row" v-for="(item, index) in desc" :key="index">
            <div style="width: 2%">{{ index + 1 + "、" }}</div>
            <div style="width: 97%">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as xlsx from "xlsx";
export default {
  name: "StructureComparison",
  data() {
    return {
      showTable: "0",
      dataList: [],
      head: [],

      fbDataList: [],

      fpDataList: [],

      fcDataList: [],
      desc: [],
    };
  },
  methods: {
    tableClick(val) {
      this.showTable = val;
    },
    exportClick() {
      let head = [];
      let header = [];
      let type = {
        "00": "合并报表",
        "01": "单体报表",
      };
      let time = {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      };
      for (let index = 0; index < this.head.length; index++) {
        head.push(
          this.head[index].company +
            this.head[index].report_year +
            time[this.head[index].report_time] +
            type[this.head[index].report_type],
          "",
          ""
        );
        header.push("项目", "金额(万元)", "占比");
      }
      let fbData1 = this.fbDataList;
      let fbData1_1 = [];
      for (let index = 0; index < fbData1.length; index++) {
        for (let index1 = 0; index1 < fbData1[index].length; index1++) {
          if (index == 0) {
            fbData1_1.push([
              fbData1[index][index1].title,
              this.thousandBitSeparator(fbData1[index][index1].value),
              fbData1[index][index1].rate,
            ]);
          } else if (index == 1) {
            fbData1_1[index1].push(
              fbData1[index][index1].title,
              this.thousandBitSeparator(fbData1[index][index1].value),
              fbData1[index][index1].rate
            );
          } else if (index == 2) {
            fbData1_1[index1].push(
              fbData1[index][index1].title,
              this.thousandBitSeparator(fbData1[index][index1].value),
              fbData1[index][index1].rate
            );
          }
        }
      }
      const fbData = [head, header, ...fbData1_1];

      let fpData1 = this.fpDataList;
      let fpData1_1 = [];
      for (let index = 0; index < fpData1.length; index++) {
        for (let index1 = 0; index1 < fpData1[index].length; index1++) {
          if (index == 0) {
            fpData1_1.push([
              fpData1[index][index1].title,
              this.thousandBitSeparator(fpData1[index][index1].value),
              fpData1[index][index1].rate,
            ]);
          } else if (index == 1) {
            fpData1_1[index1].push(
              fpData1[index][index1].title,
              this.thousandBitSeparator(fpData1[index][index1].value),
              fpData1[index][index1].rate
            );
          } else if (index == 2) {
            fpData1_1[index1].push(
              fpData1[index][index1].title,
              this.thousandBitSeparator(fpData1[index][index1].value),
              fpData1[index][index1].rate
            );
          }
        }
      }
      const fpData = [head, header, ...fpData1_1];

      let fcData1 = this.fcDataList;
      let fcData1_1 = [];
      for (let index = 0; index < fcData1.length; index++) {
        for (let index1 = 0; index1 < fcData1[index].length; index1++) {
          if (index == 0) {
            fcData1_1.push([
              fcData1[index][index1].title,
              this.thousandBitSeparator(fcData1[index][index1].value),
              fcData1[index][index1].compare,
            ]);
          } else if (index == 1) {
            fcData1_1[index1].push(
              fcData1[index][index1].title,
              this.thousandBitSeparator(fcData1[index][index1].value),
              fcData1[index][index1].compare
            );
          } else if (index == 2) {
            fcData1_1[index1].push(
              fcData1[index][index1].title,
              this.thousandBitSeparator(fcData1[index][index1].value),
              fcData1[index][index1].compare
            );
          }
        }
      }
      const fcData = [head, header, ...fcData1_1];

      const wb = xlsx.utils.book_new();
      const FB = xlsx.utils.aoa_to_sheet(fbData);
      FB["!merges"] = [
        { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, //合并第一行 第1个至第3个
        { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } }, // 合并第一行  第4个至第6个
        { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } }, // 合并第一行  第4个至第6个
      ];
      const FP = xlsx.utils.aoa_to_sheet(fpData);
      FP["!merges"] = [
        { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, //合并第一行 第1个至第3个
        { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } }, // 合并第一行  第4个至第6个
        { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } }, // 合并第一行  第4个至第6个
      ];
      const FC = xlsx.utils.aoa_to_sheet(fcData);
      FC["!merges"] = [
        { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, //合并第一行 第1个至第3个
        { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } }, // 合并第一行  第4个至第6个
        { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } }, // 合并第一行  第4个至第6个
      ];

      xlsx.utils.book_append_sheet(wb, FB, "资产负债表");
      xlsx.utils.book_append_sheet(wb, FP, "利润表");
      xlsx.utils.book_append_sheet(wb, FC, "现金流量表");
      const fileName = "结构组成对标.xlsx";
      xlsx.writeFile(wb, fileName);
    },

    getData() {
      this.desc = [];
      let fbHeader = [];
      let fbCount = [];

      let fpHeader = [];
      let fpCount = [];

      let fcHeader = [];
      let fcCount = [];

      for (let i = 0; i < this.dataList.length; i++) {
        this.desc.push(this.dataList[i].desc);
        // 资产数据处理
        fbHeader.push({
          company: this.dataList[i].company,
          report_time: this.dataList[i].report_time,
          report_type: this.dataList[i].report_type,
          report_year: this.dataList[i].report_year,
        });
        let fb = this.dataList[i].simple.FB[0].concat(
          this.dataList[i].simple.FB[1]
        );
        fbCount.push(fb);

        fpHeader.push({
          company: this.dataList[i].company,
          report_time: this.dataList[i].report_time,
          report_type: this.dataList[i].report_type,
          report_year: this.dataList[i].report_year,
          name: "占比",
        });
        fpCount.push(this.dataList[i].simple.FP);

        fcHeader.push({
          company: this.dataList[i].company,
          report_time: this.dataList[i].report_time,
          report_type: this.dataList[i].report_type,
          report_year: this.dataList[i].report_year,
          name: "变动率",
        });
        fcCount.push(this.dataList[i].simple.FC);
      }
      this.$refs.fbtable.head = fbHeader;
      this.$refs.fbtable.dataList = fbCount;
      this.head = fbHeader;
      this.fbDataList = fbCount;

      this.$refs.fptable.head = fpHeader;
      this.$refs.fptable.dataList = fpCount;
      this.fpDataList = fpCount;

      this.$refs.fctable.head = fcHeader;
      this.$refs.fctable.dataList = fcCount;
      this.fcDataList = fcCount;
    },

    thousandBitSeparator: function (cellValue) {
      let value = cellValue;
      // console.log(value);
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
  mounted() {
    this.getData();
  },
  created() {},
  destroyed() {},
  computed: {},
  watch: {
    dataList() {
      this.$nextTick(() => {
        this.getData();
      });
    },
  },
  filters: {},
  directives: {},
  components: {
    FBtable: require("./FB.vue").default,
    F_table: require("./fpfc.vue").default,
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 20px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  .title1 {
    font-size: 20px;
    color: #333;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 5px;
    }
  }
}
</style>