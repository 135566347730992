<template>
  <div class="row">
    <table
      style="flex: 1"
      v-for="(item, index) in head"
      :key="index"
      class="table table-bordered table-striped"
    >
      <thead class="bgc-be color-0 text-center table-bordered">
        <tr>
          <th :colspan="3">
            <div>{{ item.company }}</div>
            <div>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ item.report_year }}
              </el-tag>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ list[item.report_time] }}
              </el-tag>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ item.report_type == "00" ? "合并报表" : "单体报表" }}
              </el-tag>
            </div>
          </th>
        </tr>
        <tr>
          <th>项目</th>
          <th>金额(万元)</th>
          <th>{{ item.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(td, i) in dataList[index]" :key="i">
          <td class="text-left">
            <div>{{ td.title }}</div>
          </td>
          <td class="text-right">
            <div>
              {{ thousandBitSeparator(td.value) }}
            </div>
          </td>
          <td class="text-right">
            <div>{{ td.compare || td.rate }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },
      dataList: [],
      head: [],
    };
  },
  methods: {
    thousandBitSeparator: function (cellValue) {
      let value = cellValue;
      // console.log(value);
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
};
</script>

<style>
</style>