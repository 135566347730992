<template>
  <div id="Stand">
    <div class="StandSearch">
      <div class="searchTootip">请输入对标企业查询条件</div>
      <div class="Stand1">
        <div class="search1">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="按范围搜索" name="first">
              <div class="row align-items-center mt-2">
                <div class="row align-items-center">
                  <div>行业范围：</div>
                  <div>
                    <div v-show="category == 'gb'">
                      <selectTree ref="selectTreegb" isName="gb" :isGb="true" />
                    </div>
                    <div v-show="category == 'sw'">
                      <selectTree ref="selectTreesw" isName="sw" :isSw="true" />
                    </div>
                  </div>
                </div>
                <div class="row ml-3 align-items-center">
                  <div>营业收入：</div>
                  <div>
                    <el-select
                      size="small"
                      v-model="company.revenue"
                      style="width: 180px"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row ml-3 align-items-center">
                  <div>资产总计：</div>
                  <div>
                    <el-select
                      size="small"
                      style="width: 180px"
                      v-model="company.total"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div>
                  <a class="btn_blue_middle" @click="searchList"
                    ><i class="el-icon-search"></i> 搜索
                  </a>
                </div>
              </div>
              <div class="searchTable">
                <el-table
                  v-if="tableDataCompany.length > 0"
                  :data="tableDataCompany"
                  stripe
                  :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
                  style="width: 100%; font-size: 16px"
                >
                  <el-table-column
                    prop="company_name"
                    label="企业名称"
                  ></el-table-column>
                  <el-table-column
                    width="200"
                    prop="report_year"
                    label="报表日期"
                  ></el-table-column>
                  <el-table-column label="报表期间" width="200">
                    <template slot-scope="{ row }">
                      <span>
                        {{ report_time[row.report_time] }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="报表种类" width="200">
                    <template slot-scope="{ row }">
                      <span>
                        {{ row.report_type == "00" ? "合并报表" : "单体报表" }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="stock__S_or_B_code"
                    label="股票代码"
                    width="200"
                  ></el-table-column>
                  <el-table-column label="操作" width="200">
                    <template slot-scope="{ row }">
                      <a style="color: #3a85fd" @click="add_Company(row)">
                        <span>添加</span>
                      </a>
                    </template>
                  </el-table-column>
                </el-table>
                <div v-if="tableDataCompany.length > 0" class="text-right mt-3">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="Company_page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="Company_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="searchCompanyTotal"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="按公司搜索" name="second">
              <div class="row align-items-center mt-2">
                <div class="row ml-3 align-items-center">
                  <div>企业名称：</div>
                  <div>
                    <el-input
                      size="small"
                      v-model="company.company_name"
                      placeholder="请输入企业名称或股票代码"
                    ></el-input>
                  </div>
                </div>
                <div>
                  <a class="btn_blue_middle" @click="searchList"
                    ><i class="el-icon-search"></i> 搜索
                  </a>
                </div>
              </div>
              <div class="searchTable">
                <el-table
                  v-if="tableDataName.length > 0"
                  :data="tableDataName"
                  stripe
                  :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
                  style="width: 100%; font-size: 16px"
                >
                  <el-table-column
                    prop="company_name"
                    label="企业名称"
                  ></el-table-column>
                  <!-- <el-table-column
                    width="200"
                    prop="report_year"
                    label="报表日期"
                  ></el-table-column>
                  <el-table-column label="报表期间" width="200">
                    <template slot-scope="{ row }">
                      <span>
                        {{ report_time[row.report_time] }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="报表种类" width="200">
                    <template slot-scope="{ row }">
                      <span>
                        {{ row.report_type == "00" ? "合并报表" : "单体报表" }}
                      </span>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    prop="stock__S_or_B_code"
                    label="股票代码"
                    width="200"
                  ></el-table-column>
                  <el-table-column label="操作" width="200">
                    <template slot-scope="{ row }">
                      <a style="color: #3a85fd" @click="add_Company(row)">
                        <span>添加</span>
                      </a>
                    </template>
                  </el-table-column>
                </el-table>
                <div v-if="tableDataName.length > 0" class="text-right mt-3">
                  <el-pagination
                    @size-change="SizeChange"
                    @current-change="CurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="StandTable">
          <div class="title1">
            <div class="t_b"></div>
            <div class="t_p">企业对标分析</div>
            <div class="t_s">注：最多选择三家企业进行对比</div>
          </div>
          <el-table
            class="mt-3"
            :data="dataList"
            stripe
            :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
            style="width: 100%; font-size: 16px"
          >
            <el-table-column
              prop="company_name"
              label="企业名称"
            ></el-table-column>
            <el-table-column prop="report_year" label="报表日期" width="250">
            </el-table-column>
            <el-table-column label="报表期间" width="250">
              <template slot-scope="{ row }">
                <span>
                  {{ report_time[row.report_time] }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="报表种类" width="250">
              <template slot-scope="{ row }">
                <span>
                  {{ row.report_type == "00" ? "合并报表" : "单体报表" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, dataList)"
                  type="text"
                  :disabled="scope.$index == 0"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="text-center mt-5">
            <el-button
              style="width: 106px; border-color: #1989fa; color: #1989fa"
              @click="clearClick"
              >重 置</el-button
            >
            <el-button
              type="primary"
              @click="StartSubmit"
              :disabled="dataList.length <= 1"
              >开始分析</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!--    -->
    <financial
      v-if="getUserRole('financial_capability') && capability"
      ref="financial_capability"
    ></financial>
    <!--   -->
    <compositi
      v-if="getUserRole('structural_compositi') && compositi"
      ref="structural_compositi"
    ></compositi>
    <!--  -->
    <industry_ranking
      v-if="getUserRole('ranking_benchmark') && benchmark"
      ref="ranking_benchmark"
    ></industry_ranking>
    <!-- " -->
    <risk_insight
      v-if="getUserRole('risk_benchmarking') && risk"
      ref="risk_insight"
    ></risk_insight>
  </div>
</template>

<script>
import * as sesstion from "../../../store/localStorage";
import {
  default_company, //当前企业
  companylist, //搜索
  financial_capacity, //财务能力
  risk_insight, //风险洞察
  financial_capability, //结构组成
  industry_ranking, //行业排名
  checkpoint,
  logActivity,
  yearList,
} from "@/api.js";

export default {
  data() {
    return {
      param: {},
      params: {},
      activeName: "first",
      category:
        sesstion.getLocalData("company").company_type == 0 ? "gb" : "sw",
      options: [
        {
          value: "0",
          label: "0-1000万",
        },
        {
          value: "1",
          label: "1000万-1亿",
        },
        {
          value: "2",
          label: "1亿-10亿",
        },
        {
          value: "3",
          label: "10亿以上",
        },
        {
          value: "9",
          label: "全部",
        },
      ],
      industry_code: "",

      company: {
        total: "",
        revenue: "",
        company_name: "",
      },

      dataList: [],

      searchCompany: [],
      tableDataCompany: [],
      searchCompanyTotal: "",
      Company_page: 1,
      Company_size: 10,

      searchName: [],
      tableDataName: [],
      Total: "",
      page: 1,
      page_size: 10,

      report_time: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },

      // 权限部分
      capability: false, //财务
      compositi: false,
      benchmark: false, //行业
      risk: false, //风险
    };
  },
  components: {
    selectTree: require("@/components/selectTree/selectTree2.vue").default,
    financial: require("./financial/index.vue").default,
    compositi: require("./compositi/index.vue").default,
    industry_ranking: require("./industry_ranking/index.vue").default,
    risk_insight: require("./risk_insight/index.vue").default,
  },
  mounted() {
    // this.default_company();
  },
  created() {
    this.$VM[this.$route.name] = this;
  },

  watch: {},

  methods: {
    // 获取当前企业
    default_company() {
      let data = sesstion.getLocalData("companyData");
      this.param = {
        company_code: data.company_code,
        report_year: data.year,
        report_time: data.report_time,
        report_type: data.report_type,
        industry_code: data.industry_code,
      };
      default_company(this.param).then((res) => {
        this.dataList.push(res.data.result);
        this.company.total = res.data.result.total;
        this.company.revenue = res.data.result.revenue;
        this.$loadingHide();
      });
    },

    handleClick(tab, event) {},
    search(param) {
      this.$loadingShow();
      if (this.category == "sw") {
        setTimeout(() => {
          this.$refs.selectTreesw.setSwDefualtTitle();
        }, 500);
      } else {
        setTimeout(() => {
          this.$refs.selectTreegb.setGbDefualtTitle();
        }, 500);
      }
      this.params = {};
      this.dataList = [];
      setTimeout(() => {
        this.default_company();
      }, 500);
      this.params = {
        company_code: param.company_code,
        menu_id: "4",
        parsing_time: param.year,
        industry_type: param.industry_name
          ? param.industry_name.split("/").reverse()[0]
          : "",
        industry_number: param.industry_code,
        industry_range: "0",
        report_time: param.report_time,
        report_type: param.report_type,
      };
    },

    searchList() {
      this.$loadingShow();
      if (this.activeName == "first") {
        let param = {
          report_year: this.param.report_year,
          report_time: this.param.report_time,
          report_type: this.param.report_type,
          industry_code:
            this.$refs[`selectTree${this.category}`].get_industry_code(),
          revenue: this.company.revenue,
          total: this.company.total,
        };

        companylist(param).then((res) => {
          this.$loadingHide();
          if (res.data.Status == 0) {
            return this.$message.error(res.data.Message);
          } else {
            this.searchCompany = res.data.result;
            this.searchCompanyTotal = res.data.result.length;
            this.handleCurrentChange(1);
          }
        });
      } else {
        if (this.company.company_name !== "") {
          let param = {
            report_year: this.param.report_year,
            report_time: this.param.report_time,
            report_type: this.param.report_type,
            company_name: this.company.company_name,
          };

          companylist(param).then((res) => {
            this.$loadingHide();
            if (res.data.Status == 0) {
              return this.$message.error(res.data.Message);
            } else {
              this.searchName = res.data.result;
              this.Total = res.data.result.length;
              this.CurrentChange(1);
            }
          });
        } else {
          this.$loadingHide();
          return this.$message.error("请输入企业名称或股票代码");
        }
      }
    },
    //条件列表分页
    handleSizeChange(val) {
      this.currentPage = 1;
      this.Company_size = val;
    },
    handleCurrentChange(val) {
      this.Company_page = val;
      this.tableDataCompany = this.getNeedArr(
        this.searchCompany,
        this.Company_size
      )[this.Company_page - 1]; //当前页的表格数据
    },

    //公司条件列表分页
    SizeChange(val) {
      this.page = 1;
      this.page_size = val;
    },
    CurrentChange(val) {
      this.page = val;
      this.tableDataName = this.getNeedArr(this.searchName, this.page_size)[
        this.page - 1
      ]; //当前页的表格数据
    },
    // 前端处理分页
    //获取所需指定长度分割的数组;参数1为用于分割的总数组,参数2为分割数组后每个小数组的长度
    getNeedArr(array, size) {
      const length = array.length;
      //判断不是数组，或者size没有设置，size小于1，就返回空数组
      if (!length || !size || size < 1) {
        return [];
      }

      let index = 0; //用来表示切割元素的范围start
      let resIndex = 0; //用来递增表示输出数组的下标

      //根据length和size算出输出数组的长度，并且创建它。
      let result = new Array(Math.ceil(length / size));
      //进行循环
      while (index < length) {
        //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
        result[resIndex++] = array.slice(index, (index += size));
      }
      //输出到新数组
      return result;
    },
    // 添加
    add_Company(val) {
      let param = {
        company_code: val.company_code,
        report_time: val.report_time,
        report_type: val.report_type,
        report_year: val.report_year,
      };
      if (this.dataList.length < 3) {
        if (val.is_financial == 0 || val.is_financial == undefined) {
          yearList(param).then(({ data }) => {
            if (data.Status == 0) {
              return this.$message.error(data.Message);
            } else {
              this.dataList.push(val);
              const h = this.$createElement;
              this.$notify({
                // title: "标题名称",
                message: h("i", { style: "color: #3a85fd" }, "添加成功！"),
              });
            }
          });
        } else {
          return this.$message.error("选择企业为金融企业");
        }
      } else {
        return this.$message.error("最多选择三家企业进行对比");
      }
    },
    // 删除公司
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    clearClick() {
      this.dataList = []; //对比列表
      this.tableDataName = []; //公司名称列
      this.searchName = []; //公司名称列
      this.tableDataCompany = []; //范围列
      this.searchCompany = []; //范围列
      // 分页清空
      this.Total = "";
      this.page = 1;
      this.page_size = 10;
      this.searchCompanyTotal = "";
      this.Company_page = 1;
      this.Company_size = 10;

      // 分析项隐藏
      this.capability = false;
      this.compositi = false;
      this.benchmark = false;
      this.risk = false;
      this.default_company();
    },
    StartSubmit() {
      this.$loadingShow();

      // 开始分析后置空搜索项
      this.tableDataName = []; //公司名称列
      this.searchName = []; //公司名称列
      this.tableDataCompany = []; //范围列
      this.searchCompany = []; //范围列
      this.Total = "";
      this.page = 1;
      this.page_size = 10;
      this.searchCompanyTotal = "";
      this.Company_page = 1;
      this.Company_size = 10;

      let params = {
        company_code: [],
        report_year: "",
        report_time: "",
        report_type: "",
        industry_code: [],
      };
      this.dataList.map((item, index) => {
        params.company_code[index] = item.company_code;
        params.industry_code[index] = item.industry_code;
        params.report_year = item.report_year;
        params.report_time = item.report_time;
        params.report_type = item.report_type;
      });

      checkpoint(this.params).then(({ data }) => {
        if (data.Status !== "1") {
          this.$loadingHide(); //隐藏加载动画
          return this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          logActivity(this.params);

          // 财务能力
          if (this.getUserRole("financial_capability")) {
            this.capability = true;
            financial_capacity(params).then((res) => {
              this.$refs.financial_capability.dataList = res.data.result;
              setTimeout(() => {
                this.$loadingHide();
              }, 2000);
            });
          }

          //结构组成
          if (this.getUserRole("structural_compositi")) {
            this.compositi = true;
            financial_capability(params).then((res) => {
              this.$refs.structural_compositi.dataList = res.data.Result;
              setTimeout(() => {
                this.$loadingHide();
              }, 2000);
            });
          }

          //行业排名
          if (this.getUserRole("ranking_benchmark")) {
            this.benchmark = true;
            industry_ranking(params).then((res) => {
              this.$refs.ranking_benchmark.dataList = res.data.result;
              setTimeout(() => {
                this.$loadingHide();
              }, 2000);
            });
          }

          // 风险洞察
          if (this.getUserRole("risk_benchmarking")) {
            this.risk = true;
            risk_insight(params).then((res) => {
              this.$refs.risk_insight.dataList = res.data.result;
              setTimeout(() => {
                this.$loadingHide();
              }, 2000);
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#Stand {
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 30px;
  margin-top: 10px;
  .StandSearch {
    width: 100%;
    background: #fff;
    padding-bottom: 20px;
    .searchTootip {
      background: #d1e7fe;
      padding: 5px;
      font-size: #333;
    }
    .Stand1 {
      padding: 0px 20px;
      .search1 {
        padding: 20px 0px;
        border-bottom: 2px solid #ccc;
        .btn_blue_middle {
          background-color: #3a85fd;
          width: 100px;
          height: 32px;
          border-radius: 5px;
          color: #fff;
          font-size: 16px;
          margin-left: 20px;
          display: block;
          text-align: center;
          padding: 5px;
        }
        .searchTable {
          margin-top: 20px;
        }
      }
      .StandTable {
        margin-top: 30px;
        padding-bottom: 20px;
        .title1 {
          display: flex;
          align-items: center;
          .t_b {
            width: 10px;
            height: 10px;
            background: #3a85fd;
            border-radius: 50%;
          }
          .t_p {
            margin-left: 5px;
            font-size: 16px;
            font-weight: 550;
          }
          .t_s {
            margin-left: 10px;
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>