<template>
  <div ref="eles" style="width: 420px; height: 260px"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    data: Object,
  },
  mounted() {
    this.initBar();
  },
  methods: {
    initBar() {
      const title = [];
      const data = [];
      if (this.data && this.data.length) {
        for (var i = this.data.length - 1; i >= 0; i--) {
          var row = this.data[i];
          title.push(row.name);
          data.push(100 - row.pos_val);
        }
      }
      let option = {
        grid: {
          top: "5%",
          left: "20%",
          right: "3%",
          bottom: "20%",
        },

        xAxis: {
          type: "value",
          show: true,
          min: 0,
          max: 100,
          // minInterval:10,
          // maxInterval:32.5,
          interval: 10,
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: [
                "#ccc",
                "#ccc",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#FFF",
                "#FFF",
                "#ccc",
                "#ccc",
              ],
            },
          },
          axisLabel: {
            formatter: function (value, i) {
              // console.log(value,i);
              var texts = [];
              if (i == 1) {
                texts.push("{LabelStyle1|\n\n排名前\n90-100%}");
              } else if (i == 2) {
                texts.push("{LabelStyle2|\n\n排名前\n67.5-90%}");
              } else if (i == 5) {
                texts.push("{LabelStyle3|\n\n排名前\n32.5-67.5%}");
              } else if (i == 8) {
                texts.push("{LabelStyle4|\n\n排名前\n10-32.5%}");
              } else if (i == 10) {
                texts.push("{LabelStyle5|\n\n排名前\n0-10%}");
              }
              return texts;
            },
            rotate: 0.1,
            rich: {
              LabelStyle1: {
                align: "center",
                lineHeight: 15,
              },
              LabelStyle2: {
                padding: -60,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle3: {
                padding: -15,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle4: {
                padding: 2,
                align: "center",
                lineHeight: 15,
              },
              LabelStyle5: {
                padding: -5,
                align: "right",
                lineHeight: 15,
              },
            },
          },
        },
        yAxis: {
          type: "category",
          data: title,
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: "排名前0-10%",
            type: "bar",
            barWidth: "50%",
            data: data,
            color: "#529EE2",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data <= 10) {
                    return "#F2637B";
                  }
                  if (params.data <= 32.5) {
                    return "#F88E36";
                  }
                  if (params.data > 32.5 && params.data <= 67.5) {
                    return " #FAD33F";
                  }
                  if (params.data <= 90) {
                    return "#3EBB9F";
                  }
                  if (params.data <= 100) {
                    return "#529EE2";
                  }
                },
              },
            },
          },
          {
            name: "排名前10-32.5%",
            type: "bar",
            color: "#3EBB9F",
            barWidth: "0%",
          },
          {
            name: "排名前32.5-67.5%",
            barWidth: "0%",
            type: "bar",
            color: "#FAD33F",
          },
          {
            name: "排名前67.5-90%",
            barWidth: "0%",
            type: "bar",
            color: "#F88E36",
          },
          {
            name: "排名前90-100%",
            barWidth: "0%",
            type: "bar",
            color: "#F2637B",
          },
        ],
      };
      this.$echarts.init(this.$refs.eles).setOption(option);
    },
  },
};
</script>

<style>
</style>