<template>
  <div>
    <!-- {{ head }}
    {{ dataList }} -->

    <table style="flex: 1" class="table table-bordered table-striped">
      <thead class="bgc-be color-0 text-center table-bordered">
        <tr>
          <th class="text-center">项目</th>
          <th v-for="(item, index) in head" :key="index">
            <div>{{ item.company_name }}</div>
            <div>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ item.report_year }}
              </el-tag>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ list[item.report_time] }}
              </el-tag>
              <el-tag size="small" class="mr-1" effect="plain">
                {{ item.report_type == "00" ? "合并报表" : "单体报表" }}
              </el-tag>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, key) in dataList"
          :key="key"
          :style="key == 0 ? { fontWeight: 550 } : ''"
        >
          <td
            :class="i == 0 ? 'text-left' : 'text-center'"
            v-for="(item, i) in value"
            :key="i"
          >
            {{ item }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },
      dataList: [],
      head: [],
    };
  },
  methods: {},
};
</script>

<style>
</style>