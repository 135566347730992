<template>
  <div>
    <div class="content">
      <div class="title1 test1">
        <div class="title2">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          财务能力对标
        </div>
        <div>
          <el-button type="primary" @click="exportClick" size="small"
            >导出分析结果</el-button
          >
        </div>
      </div>
      <div class="mt-3">
        <div>
          <el-button-group>
            <el-button
              :type="showTable == 0 ? 'primary' : ''"
              size="medium"
              @click="tableClick('0')"
              >盈利能力</el-button
            >
            <el-button
              :type="showTable == 1 ? 'primary' : ''"
              size="medium"
              @click="tableClick('1')"
              >营运能力</el-button
            >
            <el-button
              :type="showTable == 2 ? 'primary' : ''"
              size="medium"
              @click="tableClick('2')"
              >发展能力</el-button
            >
            <el-button
              :type="showTable == 3 ? 'primary' : ''"
              size="medium"
              @click="tableClick('3')"
              >短期偿债能力</el-button
            >
            <el-button
              :type="showTable == 4 ? 'primary' : ''"
              size="medium"
              @click="tableClick('4')"
              >长期偿债能力</el-button
            >
          </el-button-group>
        </div>
        <div class="mt-3">
          <FivePower ref="FivePower"></FivePower>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as xlsx from "xlsx";
export default {
  data() {
    return {
      showTable: "0",
      dataList: [],
      head: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
    };
  },
  methods: {
    exportClick() {
      let head = ["项目"];
      let type = {
        "00": "合并报表",
        "01": "单体报表",
      };
      let time = {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      };
      for (let index = 0; index < this.head.length; index++) {
        head.push(
          this.head[index].company_name +
            this.head[index].report_year +
            time[this.head[index].report_time] +
            type[this.head[index].report_type]
        );
      }

      const data = [
        head,
        ...this.data1,
        ...this.data2,
        ...this.data3,
        ...this.data4,
        ...this.data5,
      ];
      const ws = xlsx.utils.aoa_to_sheet(data);
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, ws, "sheet1");
      const fileName = "财务能力对标.xlsx";
      xlsx.writeFile(wb, fileName);
    },
    tableClick(val) {
      this.showTable = val;
      if (val == "0") {
        this.$refs.FivePower.dataList = this.data1;
      } else if (val == "1") {
        this.$refs.FivePower.dataList = this.data2;
      } else if (val == "2") {
        this.$refs.FivePower.dataList = this.data3;
      } else if (val == "3") {
        this.$refs.FivePower.dataList = this.data4;
      } else {
        this.$refs.FivePower.dataList = this.data5;
      }
    },
    getData() {
      let 盈利能力 = [];
      let 营运能力 = [];
      let 发展能力 = [];
      let 短期偿债能力 = [];
      let 长期偿债能力 = [];
      this.head = [];
      for (let index = 0; index < this.dataList.length; index++) {
        盈利能力.push(this.dataList[index].financial.盈利能力);
        营运能力.push(this.dataList[index].financial.营运能力);
        发展能力.push(this.dataList[index].financial.发展能力);
        短期偿债能力.push(this.dataList[index].financial.短期偿债能力);
        长期偿债能力.push(this.dataList[index].financial.长期偿债能力);
        this.head.push({
          company_name: this.dataList[index].company_name,
          report_year: this.dataList[index].report_year,
          report_time: this.dataList[index].report_time,
          report_type: this.dataList[index].report_type,
        });
      }
      this.$refs.FivePower.head = this.head;

      let yl = [];
      for (let i = 0; i < 盈利能力.length; i++) {
        yl.push(this.obj(盈利能力[i]));
      }

      let yy = [];
      for (let i = 0; i < 营运能力.length; i++) {
        yy.push(this.obj(营运能力[i]));
      }
      let fz = [];
      for (let i = 0; i < 发展能力.length; i++) {
        fz.push(this.obj(发展能力[i]));
      }
      let dq = [];
      for (let i = 0; i < 短期偿债能力.length; i++) {
        dq.push(this.obj(短期偿债能力[i]));
      }
      let cq = [];
      for (let i = 0; i < 长期偿债能力.length; i++) {
        cq.push(this.obj(长期偿债能力[i]));
      }

      let data1 = yl[0];
      for (let i = 0; i < data1.length; i++) {
        yl.map((res, index) => {
          if (index == 1) {
            data1[i].push(res[i][1]);
          }
          if (index == 2) {
            data1[i].push(res[i][1]);
          }
        });
      }
      let data2 = yy[0];
      for (let i = 0; i < data2.length; i++) {
        yy.map((res, index) => {
          if (index == 1) {
            data2[i].push(res[i][1]);
          }
          if (index == 2) {
            data2[i].push(res[i][1]);
          }
        });
      }

      let data3 = fz[0];
      for (let i = 0; i < data3.length; i++) {
        fz.map((res, index) => {
          if (index == 1) {
            data3[i].push(res[i][1]);
          }
          if (index == 2) {
            data3[i].push(res[i][1]);
          }
        });
      }
      let data4 = dq[0];
      for (let i = 0; i < data4.length; i++) {
        dq.map((res, index) => {
          if (index == 1) {
            data4[i].push(res[i][1]);
          }
          if (index == 2) {
            data4[i].push(res[i][1]);
          }
        });
      }

      let data5 = cq[0];
      for (let i = 0; i < data5.length; i++) {
        cq.map((res, index) => {
          if (index == 1) {
            data5[i].push(res[i][1]);
          }
          if (index == 2) {
            data5[i].push(res[i][1]);
          }
        });
      }
      this.data1 = data1;
      this.data2 = data2;
      this.data3 = data3;
      this.data4 = data4;
      this.data5 = data5;

      this.tableClick(0);
    },

    obj(value) {
      const keys = Object.keys(value);
      const values = Object.values(value);
      const result = keys.map((key) => [key, values[keys.indexOf(key)]]);
      return result;
    },
  },
  mounted() {
    // this.getData();
  },
  created() {},
  destroyed() {},
  computed: {},
  watch: {
    dataList() {
      this.$nextTick(() => {
        this.getData();
      });
    },
  },
  filters: {},
  directives: {},
  components: {
    FivePower: require("./fivepower.vue").default,
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 20px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  .title1 {
    font-size: 20px;
    color: #333;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 5px;
    }
  }
}
</style>