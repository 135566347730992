<template>
  <div>
    <div class="content">
      <div class="title1 test1">
        <div class="title2">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          行业排名对标
        </div>
        <div>
          <el-button @click="exportClickTop" type="primary" size="small"
            >导出分析结果</el-button
          >
        </div>
      </div>
      <div class="mt-3" id="tableBox1">
        <table style="width: 100%" class="table table-bordered table-striped">
          <thead class="bgc-be color-0 text-center table-bordered">
            <tr>
              <th v-for="item in dataList" :key="item">
                <div>{{ item.company_name }}</div>
                <div>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ item.report_year }}
                  </el-tag>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ list[item.report_time] }}
                  </el-tag>
                  <el-tag size="small" class="mr-1" effect="plain">
                    {{ item.report_type == "00" ? "合并报表" : "单体报表" }}
                  </el-tag>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center" v-for="item in dataList" :key="item">
                <div style="display: flex; justify-content: center">
                  <Bar ref="bar" :data="item.financial"></Bar>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas"; //文本转图片
export default {
  data() {
    return {
      list: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },
      dataList: [
        // {
        //   company_name: "奥瑞德光电股份有限公司",
        //   report_year: "2023",
        //   report_time: "02",
        //   report_type: "00",
        //   financial: [
        //     {
        //       name: "长期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "短期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "盈利能力",
        //       pos_val: 78.75,
        //       color: "orange",
        //       area: [67.5, 90],
        //     },
        //     {
        //       name: "营运能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //     {
        //       name: "发展能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //   ],
        // },
        // {
        //   company_name: "奥瑞德光电股份有限公司",
        //   report_year: "2023",
        //   report_time: "02",
        //   report_type: "00",
        //   financial: [
        //     {
        //       name: "长期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "短期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "盈利能力",
        //       pos_val: 78.75,
        //       color: "orange",
        //       area: [67.5, 90],
        //     },
        //     {
        //       name: "营运能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //     {
        //       name: "发展能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //   ],
        // },
        // {
        //   company_name: "奥瑞德光电股份有限公司",
        //   report_year: "2023",
        //   report_time: "02",
        //   report_type: "00",
        //   financial: [
        //     {
        //       name: "长期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "短期偿债能力",
        //       pos_val: 50.0,
        //       color: "yellow",
        //       area: [32.5, 67.5],
        //     },
        //     {
        //       name: "盈利能力",
        //       pos_val: 78.75,
        //       color: "orange",
        //       area: [67.5, 90],
        //     },
        //     {
        //       name: "营运能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //     {
        //       name: "发展能力",
        //       pos_val: 95.0,
        //       color: "red",
        //       area: [90, 100],
        //     },
        //   ],
        // },
      ],
    };
  },
  components: {
    Bar: require("./bar.vue").default,
  },
  methods: {
    exportClickTop() {
      const imgList = document.getElementById("tableBox1");
      html2canvas(imgList).then((canvas) => {
        // 创建一个新的图像元素
        var img = new Image();
        // 设置图像的源为canvas生成的DataURL
        img.src = canvas.toDataURL("image/png");
        // 创建一个下载链接
        var link = document.createElement("a");
        link.href = img.src;
        link.download = "行业排名对标.png"; // 名称可以修改
        link.click();
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 20px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  .title1 {
    font-size: 20px;
    color: #333;
    font-weight: 550;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 5px;
    }
  }
}
</style>